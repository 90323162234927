import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"

import "../components/styles.css"
import useWindowSize from "../hooks/useWindowSize"

import Banner from "../images/nikki-leone-banner.svg"
import TikTok from "../images/tiktok.svg"
import Instagram from "../images/instagram.svg"
import Email from "../images/email.svg"
import OG from "../images/nikki-leone-og.jpg"

const Wrapper = styled(motion.div)`
position: relative; 
width: 100%; 
background: linear-gradient(180deg, rgba(240, 92, 9, 0.114) 0%, rgba(239, 87, 1, 0.19) 100%);
font-family: 'IBM Plex Mono', monospace;
font-weight: 400;
display: flex;
  align-items: center;
  justify-content: center;
`
const Container = styled.div`
  padding-bottom: 48px;
  div {
    margin-bottom: 0;
    text-align: right;
  }
  img {
    margin: 0;
    padding: 0;
  }
`
const Link = styled.a`
  position: relative;
  display: inline-block;
  font-size: 15px;
  color: #000000;
  text-decoration: none;
  letter-spacing: .5px;
  transition: .3s ease-in-out;
  :visited {
    color: inherit;
  }
  :hover {
    transform: scale(1.05);
  }

  padding-bottom: 4px;
  border-bottom: 2px dashed #1A81BB;

  :before {
    content: "";
    position: absolute;
    left: -24px;
    top: 10px;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    
  }

  &.primary {
    font-weight: 700;
    margin-bottom: 16px;

    :before {
    background-image: url(${Email});
  }

  }
  &.red {
    border-color: #EF5701;
    :before {
    background-image: url(${TikTok});
  }
  }
  &.green {
    border-color: #46AA92;
    :before {
    background-image: url(${Instagram});
    
  }
  }
  
`
const FloatingContent = styled.div`
@keyframes float {
	0% {
		
		transform: translatey(0px);
	}
	50% {
		
		transform: translatey(-4px);
	}
	100% {
		
		transform: translatey(0px);
	}
}
animation: float 6s ease-in-out infinite;
`

const IndexPage = () => {
  const size = useWindowSize();
  return (
  
    <>
    <Helmet>
    title={`Nikki Leone`}
    <meta

          name="description"
          content="Virginia Beach based multidisciplinary artist. Especially awesome at sculpture, display work, installation, and tufting."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={OG}
        />
        <meta property="og:image:width" content="1000" />
        <meta property="og:image:height" content="1000" />
        <html lang="en" />
    </Helmet>
  <Wrapper initial={{height: '100vh'}} animate={{height: size.height}}>
      <StaticImage
      src="../images/background.png"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt=""
      style={{ opacity: "0.3", margin: 0, position: 'absolute', zIndex: '-1', top: 0, bottom: 0, left: 0, right: 0, }}
    />

    <Container>
      <FloatingContent>
        <div style={{marginBottom: 16}}>
          <img src={Banner} alt="Nikki Leone" />
        </div>
        
      <div>
        <Link className="primary" href="mailto:nikki@nikkileone.com">nikki@nikkileone.com</Link>
      </div>
      <div>
        <Link className="green" href="https://www.instagram.com/poplar_pine/">@poplar_pine</Link>
      </div>
      <div style={{marginBottom: 16}}>
      <Link className="green" href="https://www.instagram.com/objects_in_progress/">@objects_in_progress</Link>
      </div>
      <div>
      <Link className="red" href="https://www.tiktok.com/@objects_in_progress">@objects_in_progress</Link>
      </div>
      

      <div style={{marginBottom: 0, position: 'relative'}}>
        <StaticImage
      src="../images/headshot.png"
      layout="fixed"
      quality={95}
      width={156}
      placeholder="none"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt=""
      style={{marginLeft: 'auto', marginTop: '80px'}}
    />

    <StaticImage
      src="../images/color.png"
      layout="fixed"
      quality={100}
      width={196}
      formats={["AUTO", "WEBP", "AVIF"]}
      placeholder="none"
      alt=""
      style={{position: 'absolute', bottom: '-48px', left: '0px', zIndex: '-1'}}
    />
      </div>
      </FloatingContent>
      
    </Container>
    
    </Wrapper>
    </>
    
)}

export default IndexPage
